import { Japanese } from 'flatpickr/dist/l10n/ja';
import { endOfYesterday } from 'date-fns';
import { getShortDate, getFullDate } from '@/filters/date';
const now = new Date()
now.setHours(0)
now.setMinutes(0)
export default {
  data: function() {
    return {
      // date: null,
      config: {
        locale: Japanese,
        start: {
          locale: Japanese,
          minDate: getShortDate(new Date()),
          maxDate: '',
        },
        end: {
          locale: Japanese,
          minDate: getShortDate(new Date()),
          maxDate: '',
        },
        birthday: {
          locale: Japanese,
          minDate: new Date('1900-01-01'),
          maxDate: endOfYesterday(),
        },
        noLimit: {
          locale: Japanese,
          minDate: '',
          maxDate: '',
        },
        startWithTime: {
          locale: Japanese,
          minDate: this.isConfigTimeNow ? new Date(getFullDate(new Date())) : getFullDate(now),
          maxDate: '',
          enableTime: this.isEnableStartTime,
          dateFormat: this.isConfigNoStartTime ? 'Y-m-d' : this.isConfigNoMinutes ? 'Y-m-d H:00' : 'Y-m-d H:i',
          time_24hr: true,
          defaultHour: this.isConfigTimeNow ? new Date().getHours().toString() : this.defaultHour,
          defaultMinute: this.isConfigTimeNow ? new Date().getMinutes().toString() : this.defaultMinute,
          minuteIncrement: this.isConfigNoMinutes ? 60 : 1,
        },
        endWithTime: {
          locale: Japanese,
          minDate: getFullDate(new Date()),
          maxDate: '',
          enableTime: this.isEnableEndTime,
          time_24hr: true,
          dateFormat: this.isConfigNoEndTime ? 'Y-m-d' : this.isConfigNoMinutes ? 'Y-m-d H:00' : 'Y-m-d H:i',
          defaultHour: this.isConfigNoMinutes ? '0' : '23',
          defaultMinute: this.isConfigNoMinutes ? '0' : '59',
          minuteIncrement: this.isConfigNoMinutes ? 60 : 1,
          onOpen: this.isConfigNoEndTime ? null : this.handleOpen,
          onValueUpdate: this.handleDateTimeChange,
        },
        startWithHourMinuteTime: {
          locale: Japanese,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          defaultHour: '9',
          defaultMinute: '0',
          minuteIncrement: 1,
          time_24hr: true
        },
        endWithHourMinuteTime: {
          locale: Japanese,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          defaultHour: '17',
          defaultMinute: '59',
          minuteIncrement: 1,
          time_24hr: true
        }
      },
    };
  },
  methods: {
    startChange(selectedDates, dateStr, instance) {
      const isConfigEndTime = (this.config.endWithTime.dateFormat === instance.config.dateFormat);
      const end = isConfigEndTime || this.isConfigNoMinutes ? this.config.endWithTime : this.config.end;
      if (this.handleHourMinute(dateStr, end, 'minTime')) return;
      if (dateStr) {
        this.$set(end, 'minDate', dateStr);
      } else {
        this.$set(end, 'minDate', isConfigEndTime ? getFullDate(new Date()) : getShortDate(new Date()));
      }
    },
    endChange(selectedDates, dateStr, instance) {
      const isConfigStartTime = (this.config.startWithTime.dateFormat === instance.config.dateFormat);
      const start = isConfigStartTime ? this.config.startWithTime : this.config.start;
      if (this.handleHourMinute(dateStr, start, 'maxTime')) return;
      if (dateStr) {
        this.$set(start, 'maxDate', dateStr);
      } else {
        this.$set(start, 'maxDate', '');
      }
    },
    closeAlert() {
      this.showAlert = false;
    },
    handleOpen() {
      if (!this.endDate) {
        this.endDate = this.startDate ? this.startDate?.slice(0, 10) + ' 23:59' : '';
      }
    },
    handleHourMinute(value, config, field) {
      if (this.type === 'hourMinute' && value) {
        const [hours, minutes] = value.split(':').map(Number);
        this.$set(config, field, new Date().setHours(hours, minutes));
        return true;
      }
      return false;
    },
    handleDateTimeChange(selectedDates, dateStr) {
      if (new Date(this.endDate) > Date.now()) {
        const now = new Date();
        now.setHours(now.getHours() + 1);
        if (new Date(dateStr) < now) {
          this.$set(this.config.endWithTime, 'minDate', getFullDate(new Date()));
        }
      }
    }
  },
};
